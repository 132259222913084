import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Container from '../components/Container'

const Kostnadsanalyser = props => {
  const { instance, lang } = props.pageContext
  const title = `Kostnadsanalyser | ${instance.title}`
  const description =
    'Ønsker du å utføre en profesjonell kostnadsanalyse? Det kan vi i Cost Partner Norge AS bistå deg med. Ta kontakt her.'

  return (
    <Layout {...props}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      <div className="main-content content-module">
        <Container textWidth>
          <h1>Kostnadsanalyser</h1>
          <p>
            <strong>
              Ønsker du en profesjonell kostnadsanalyse for din bedrift? Det kan
              vi i Cost Partner bistå deg med. Vi utfører analyser innenfor
              mange ulike kostnadskategorier. Ta kontakt for mer informasjon om
              hvordan vi kan bistå deg og din bedrift!
            </strong>
          </p>
          <p>
            <Link to={`/${instance.id}/${lang}/contact/`}>Ta kontakt</Link>
          </p>
          <p>
            For mange virksomheter er det viktigere enn noen gang å se hvor man
            kan spare penger, og spesialister fra Cost Partner står klar til å
            hjelpe.
          </p>

          <h2>Hva er en kostnadsanalyse?</h2>
          <p>
            En kostnadsanalyse måler blant annet forbruk av ressurser. De fleste
            bedrifters overordnede mål, uansett bransje, er å redusere
            ressursbruken i ulike verdiskapningsprosesser.
          </p>
          <p>
            En reduksjon av ressursforbruk skal selvfølgelig skje uten at det
            går utover ansatte eller kunder, og med en profesjonell
            kostnadsanalyse kan man vurdere og revurdere ressursbruken.
          </p>
          <p>
            I en analyse kan man se på alt fra faste og variable kostnader, og
            direkte og indirekte kostnader. De aller fleste bedrifter kan dra
            nytte av kostnadsanalyser, uansett hvilken bransje du holder på i.
          </p>

          <h2>Hvorfor kostnadsanalyser?</h2>
          <p>
            En kostnadsanalyse er med på å beregne lønnsomhet, og kan være svært
            gunstig ved større avgjørelser. Vi i Cost Partner gjennomfører
            kostnadsanalyser innen alle indirekte kostnader, og vi avdekker også
            konkret innsparingspotensial og fremforhandler nye
            leverandøravtaler.
          </p>
          <p>
            Vi kan blant annet hjelpe bedriften med å se på hvordan dere kan få
            mest mulig kundeverdi.
          </p>

          <h2>Kostnadskategorier</h2>
          <p>
            Den gjennomsnittlige besparelsen ligger på ca 30%. Dette oppnås
            kostnads- og risikofritt for kunden, fordi vi kun beregner honorar
            når besparelse oppnås.
          </p>
          <p>Vi utfører blant annet analyser innen kostnadskategorier som:</p>
          <ul>
            <li>
              Facility services: Renhold, kantinedrift, drikkeautomater,
              renovasjon, sikkerhet, energi, serviceavtaler
            </li>
            <li>
              Forbruksmateriell: Kontorrekvisita, datarekvisita,
              driftsrekvisita, trykksaker, emballasje, arbeidstøy- og vern,
              industrirekvisita
            </li>
            <li>Tele og IT: Telefon, kontormaskiner</li>
            <li>Transport: Bil, tog, sjøfrakt, flyfrakt</li>
            <li>Annet: Billeasing, banktjenester</li>
          </ul>

          <h2>Hvordan spare kostnader?</h2>
          <p>
            Noen faktorer man kan se på er å fremforhandle bedre{' '}
            <Link to={`/${instance.id}/${lang}/innkjopsavtaler/`}>
              innkjøpsavtaler
            </Link>
            , samt se på om det er mulig å spare på ressursforbruk. Her kan man
            også for eksempel se på menneskelige aktiviteter og om noe av dette
            kan digitaliseres.
          </p>
          <p>
            I tillegg kan man se på om det kan lønne seg å ha deltidsansatte
            og/eller frilansere til å utføre visse oppgaver.
          </p>
          <p>
            Kontorkostnader er også en annen del av økonomien som ofte spiser en
            del av budsjettet. Kan man for eksempel se på rimelige lokaler, dele
            lokaler, leie ut deler av lokalet, også videre.
          </p>
          <p>
            Dette er noe av det vi kan hjelpe din bedrift med å se på. Vi ønsker
            å være din Cost Partner.
          </p>

          <h2>Om Cost Partner Norge AS</h2>
          <p>
            Cost Partner AS er din partner i kostnadsbesparelse og
            avtaleforhandling. Vi er et konsulentselskap som jobber med
            besparelser på alle indirekte og administrative kostnader.
          </p>
          <p>
            En omfattende analyseprosess og vår unike database med bl.a.
            produkter, priser og leverandørinformasjon, samt et betydelig
            innkjøpsvolum, gir besparelser som selskaper ikke kan oppnå på egen
            hånd.
          </p>
          <p>
            Vi tar ikke honorar dersom vi ikke oppnår besparelse. Vi kaller
            dette for no cure, no pay. Kunden skal komme til oss og være trygg
            på at vi finner frem til økonomiske løsninger og besparelser.
          </p>

          <h3>Vår 8-trinns prosess</h3>
          <p>
            Vi benytter oss av en 8-trinns prosess når vi arbeider med deg som
            kunde. De første fire trinnene tar vi oss gjennom på ca. 3 uker og
            er 100 prosent uforpliktende:
          </p>
          <ol>
            <li>Vi inngår en samarbeidsavtale med deres firma.</li>
            <li>
              Vi starter datainnsamlingen og samler informasjon både om dere og
              om markedet.
            </li>
            <li>
              Vi foretar en kostnadsanalyse for å finne ut hvor dere kan spare
              penger innad i bedrifter, både når det kommer til Facility
              services, forbruksmateriell, tele og IT, transport og andre
              områder som for eksempel banktjenester.
            </li>
            <li>
              Vi presenterer resultatene av analysen for dere i et analysemøte,
              der dere bestemmer om dere ønsker å gå videre med oss som partnere
              på veien mot kostnadsbesparelse.
              <br />
              <br />
              Trinn 5 til 7 jobber vi med i ca 5 uker dersom dere velger å gå
              videre med planene våre.
              <br />
              <br />
            </li>
            <li>
              Vi setter igang en anbudsprosess der vi bestemmer hvilke
              leverandører som skal inviteres ut i fra visse
              kravspesifikasjoner, og det snakkes om hvilken minstebesparelse
              som må oppnås.
            </li>
            <li>
              Vi presenterer en resultatrapport for dere med det vi kom frem til
              i anbudsprosessen.
            </li>
            <li>
              Dere implementerer de nye avtalene i driften av bedriften deres og
              opplever raskt forskjell.
            </li>
          </ol>
          <p>
            Trinn 8 skjer 52 uker etter at vi inngår samarbeidsavtalen med dere
            og går ut på å gjennomføre etterkontroller. På denne måten finner vi
            ut hvor mye dere har spart det første året med de nye avtalene vi
            har forhandlet frem, og dermed hva vår provisjon skal bli.
          </p>

          <h2>Ta kontakt med oss i dag</h2>
          <p>
            Ønsker du å vite mer om Cost Partner og om hva vi kan tilby deg? Ta
            gjerne kontakt med oss allerede i dag. Vi tar gjerne en
            uforpliktende samtale om hvordan vi kan hjelpe din bedrift, både med
            innkjøpsavtaler, kostnadsanalyser og{' '}
            <Link to={`/${instance.id}/${lang}/innkjopsradgiver/`}>
              annen rådgivning
            </Link>
            .
          </p>
          <p>
            <Link to={`/${instance.id}/${lang}/contact/`}>Ta kontakt</Link>
          </p>
        </Container>
      </div>
    </Layout>
  )
}

export default Kostnadsanalyser
